<template>
  <sms-properties-card />
</template>

<script>
// @ is an alias to /src
import SmsPropertiesCard from '@/components/sms/PropertiesCard.vue';

export default {
  name: 'PipelineDashboard',
  components: {
    SmsPropertiesCard,
  },
  data() {
    return {};
  },
};
</script>
